import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_LIST_FAILURE,
  ORGANISATION_DETAILS_REQUEST,
  ORGANISATION_DETAILS_SUCCESS,
  ORGANISATION_DETAILS_FAILURE,
  CLEAR_ORGANISATION_DETAILS,
  POST_ORGANISATION_REQUEST,
  POST_ORGANISATION_SUCCESS,
  POST_ORGANISATION_FAILURE,
  PUT_ORGANISATION_REQUEST,
  PUT_ORGANISATION_SUCCESS,
  PUT_ORGANISATION_FAILURE,
} from "actions/organisation";

const initialState = {
  loading: false,
  list: [],
  details: {},
  error: {},
};

export default function organisation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ORGANISATION_LIST_REQUEST:
    case POST_ORGANISATION_REQUEST:
    case PUT_ORGANISATION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }

    case ORGANISATION_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        details: {},
      };
    }

    case ORGANISATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case ORGANISATION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case CLEAR_ORGANISATION_DETAILS: {
      return {
        ...state,
        details: {},
      };
    }

    case POST_ORGANISATION_SUCCESS:
    case PUT_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case ORGANISATION_LIST_FAILURE:
    case ORGANISATION_DETAILS_FAILURE:
    case POST_ORGANISATION_FAILURE:
    case PUT_ORGANISATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: {
          ...payload,
          message: payload?.message || "Something went wrong",
        },
      };
    }

    default:
      return state;
  }
}
