import { combineReducers } from "redux";
import session from "./session";
import organisation from "./organisation";
import user from "./user";
import profile from "./profile";
import projects from "./projects";
import validation from "./validation";
import csvColumns from "./assets/csvColumns";
import network from "./assets/network";
import attributes from "./assets/attributes";
import meterProfiles from "./assets/meterProfiles";
import scenarios from "./scenarios/scenarios";
import customerClasses from "./customerClasses/customerClasses";
import technologies from "./technology/technologies";
import battery from "./technology/battery";
import solar from "./technology/solar";
import solarProfiles from "./technology/solarProfiles";
import techAvailability from "./technology/techAvailability";
import rebateCurves from "./technology/rebateCurves";
import costCurves from "./technology/costCurves";
import products from "./technology/products";
import appliances from "./appliance/appliances";
import appPrices from "./appliance/prices";
import subloadMagnitudes from "./appliance/subloadMagnitudes";
import subloadIntervals from "./appliance/subloadIntervals";
import defaultTariffs from "./tariff/defaultTariffs";
import feedInTariffGrowth from "./tariff/feedInTariffGrowth";
import gasDailyBlock from "./tariff/gasDailyBlock";
import gasDeclBlock from "./tariff/gasDeclBlock";
import gasTariffs from "./tariff/gasTariffs";
import tariffComponents from "./tariff/tariffComponents";
import tariffs from "./tariff/tariffs";
import vehicleCostCurves from "./vehicle/costCurves";
import evIntervals from "./vehicle/evIntervals";
import evModelAvailability from "./vehicle/evModelAvailability";
import evTariffUptake from "./vehicle/evTariffUptake";
import fuelCost from "./vehicle/fuelCost";
import vehicleStock from "./vehicle/vehicleStock";
import chargingLocations from "./vehicle/chargingLocations";
import preferredChargingLocation from "./vehicle/preferredChargingLocation";
import vehicles from "./vehicle/vehicles";
import customerAttributes from "./agent/customerAttributes";
import customerConsumptionBands from "./agent/customerConsumptionBands";
import customerGrowth from "./agent/customerGrowth";
import customerIntervals from "./agent/customerIntervals";
import applianceMix from "./applianceMix/applianceMix";
import roi from "./roi/roi";
import assetHierarchy from "./assets/assetHierarchy";
import vehicleProducts from "./vehicle/vehicleProducts";
import applianceProducts from "./appliance/applianceProducts";
import simulations from "./simulations";
import agentDesign from "./agent/agentDesign";
import customerGrowthResult from "./agent/customerGrowthResult";

const rootReducer = combineReducers({
  session,
  organisation,
  user,
  profile,
  projects,
  validation,
  csvColumns,
  network,
  attributes,
  meterProfiles,
  scenarios,
  customerClasses,
  technologies,
  battery,
  solar,
  solarProfiles,
  techAvailability,
  rebateCurves,
  costCurves,
  products,
  appliances,
  appPrices,
  subloadMagnitudes,
  subloadIntervals,
  defaultTariffs,
  feedInTariffGrowth,
  gasDailyBlock,
  gasDeclBlock,
  gasTariffs,
  tariffComponents,
  tariffs,
  vehicleCostCurves,
  evIntervals,
  evModelAvailability,
  evTariffUptake,
  fuelCost,
  vehicleStock,
  chargingLocations,
  preferredChargingLocation,
  vehicles,
  customerAttributes,
  customerConsumptionBands,
  customerGrowth,
  customerIntervals,
  applianceMix,
  roi,
  assetHierarchy,
  vehicleProducts,
  applianceProducts,
  simulations,
  agentDesign,
  customerGrowthResult,
});

export default rootReducer;
