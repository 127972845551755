import {
  FolderOutlined,
  SettingsOutlined,
  ExitToApp,
  AccountCircleOutlined,
} from "@mui/icons-material";

export const menuList = [
  {
    label: "Workspace",
    menus: [
      { label: "Projects", route: "/projects", icon: <FolderOutlined /> },
      {
        label: "Organisation Management",
        route: "/settings",
        icon: <SettingsOutlined />,
      },
    ],
  },
  {
    label: "Profile",
    menus: [
      {
        label: "My Profile",
        route: "/profile",
        icon: <AccountCircleOutlined />,
      },
      { label: "Logout", route: "/logout", icon: <ExitToApp /> },
    ],
  },
];
