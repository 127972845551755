import {
  GENERATE_AGENT_DESIGN_REQUEST,
  GENERATE_AGENT_DESIGN_SUCCESS,
  GENERATE_AGENT_DESIGN_FAILURE,
  GET_AGENT_DESIGN_LIST_REQUEST,
  GET_AGENT_DESIGN_LIST_SUCCESS,
  GET_AGENT_DESIGN_LIST_FAILURE,
} from "actions/agent/agentDesign";
import { uSimError } from "utils/override";

const initialState = {
  loading: false,
  list: [],
  generateLoading: false,
};

export default function agentDesign(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GENERATE_AGENT_DESIGN_REQUEST: {
      return {
        ...state,
        generateLoading: true,
      };
    }

    case GENERATE_AGENT_DESIGN_SUCCESS: {
      return {
        ...state,
        generateLoading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GENERATE_AGENT_DESIGN_FAILURE: {
      uSimError(payload?.message || "Something went wrong");
      return {
        ...state,
        generateLoading: false,
      };
    }

    case GET_AGENT_DESIGN_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_AGENT_DESIGN_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_AGENT_DESIGN_LIST_FAILURE: {
      uSimError(payload?.message || "Something went wrong");
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
