import { API } from "aws-amplify";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { getProjectDetails } from "actions/projects";

export const GET_SCENARIOS_DOWNLOAD_TEMPLATE_REQUEST =
  "@@scenarios/GET_SCENARIOS_DOWNLOAD_TEMPLATE_REQUEST";
export const GET_SCENARIOS_DOWNLOAD_TEMPLATE_SUCCESS =
  "@@scenarios/GET_SCENARIOS_DOWNLOAD_TEMPLATE_SUCCESS";
export const GET_SCENARIOS_DOWNLOAD_TEMPLATE_FAILURE =
  "@@scenarios/GET_SCENARIOS_DOWNLOAD_TEMPLATE_FAILURE";

export function getScenariosDownloadTemplate(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_SCENARIOS_DOWNLOAD_TEMPLATE_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=scen_scenarios&organisation=${organisation}&sample_table=true`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_SCENARIOS_DOWNLOAD_TEMPLATE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SCENARIOS_DOWNLOAD_TEMPLATE_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SCENARIOS_LIST_REQUEST =
  "@@scenarios/GET_SCENARIOS_LIST_REQUEST";
export const GET_SCENARIOS_LIST_SUCCESS =
  "@@scenarios/GET_SCENARIOS_LIST_SUCCESS";
export const GET_SCENARIOS_LIST_FAILURE =
  "@@scenarios/GET_SCENARIOS_LIST_FAILURE";

export function getScenariosList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_SCENARIOS_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=scen_scenarios&organisation=${organisation}&sample_table=false`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_SCENARIOS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SCENARIOS_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const DELETE_SCENARIOS_LIST_REQUEST =
  "@@scenarios/DELETE_SCENARIOS_LIST_REQUEST";
export const DELETE_SCENARIOS_LIST_SUCCESS =
  "@@scenarios/DELETE_SCENARIOS_LIST_SUCCESS";
export const DELETE_SCENARIOS_LIST_FAILURE =
  "@@scenarios/DELETE_SCENARIOS_LIST_FAILURE";

export function deleteScenariosList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: DELETE_SCENARIOS_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=scen_scenarios&organisation=${organisation}`;
      }

      const response = await API.del("usimAPI", url);
      dispatch({
        type: DELETE_SCENARIOS_LIST_SUCCESS,
        payload: response,
      });
      dispatch(getProjectDetails(id));
    } catch (error) {
      dispatch({
        type: DELETE_SCENARIOS_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const UPLOAD_SCENARIOS_DATA_REQUEST =
  "@@scenarios/UPLOAD_SCENARIOS_DATA_REQUEST";
export const UPLOAD_SCENARIOS_DATA_SUCCESS =
  "@@scenarios/UPLOAD_SCENARIOS_DATA_SUCCESS";
export const UPLOAD_SCENARIOS_DATA_FAILURE =
  "@@scenarios/UPLOAD_SCENARIOS_DATA_FAILURE";

export function uploadScenariosData(
  id = "",
  organisation,
  file,
  columnMappings,
  callback
) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: UPLOAD_SCENARIOS_DATA_REQUEST,
    });
    try {
      let url = "/presignedurl";
      if (id) {
        url = `${url}?project_id=${id}&table_name=scen_scenarios`;
      }

      if (!isEmpty(columnMappings)) {
        url = `${url}&column_mappings=${encodeURI(
          JSON.stringify(columnMappings)
        )}`;
      }

      const response = await API.get("usimAPI", url);

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      };
      await fetch(response.uploadURL, requestOptions).then((response) => {
        if (response.status === 200) {
          toast.success("Data uploaded successfully!");

          setTimeout(() => {
            dispatch(getProjectDetails(id));
            dispatch(getScenariosList(id, organisation));
          }, 2000);
          callback({
            success: true,
          });
        } else {
          dispatch({
            type: UPLOAD_SCENARIOS_DATA_FAILURE,
            payload: {
              message: "Upload data failed.",
            },
          });
        }
      });

      dispatch({
        type: UPLOAD_SCENARIOS_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_SCENARIOS_DATA_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
