import { Box } from "@mui/material";
import energeiaCircle from "images/energeiaCircle.png";

const EnergeiaCircle = ({ width = "77" }) => {
  return (
    <Box>
      <img src={energeiaCircle} alt="" width={width} />
    </Box>
  );
};

export default EnergeiaCircle;
