import {
  Box,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const listStyles = () => {
  return {
    width: "200px",
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#F6F7FC",
      color: "#2B7FE0",
      borderRadius: "4px",
    },
  };
};

const DropPopper = ({ anchorEl, setAnchorEl, options = [], onChange }) => {
  const closeActionPopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "action-popover" : undefined;

  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeActionPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={listStyles}>
          {options &&
            options.map((item, i) => (
              <ListItem key={i} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    onChange(item.value);
                    closeActionPopover();
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    mb: "1px",
                  }}
                >
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Popover>
    </Box>
  );
};

export default DropPopper;
