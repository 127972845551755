import { Loader } from "components";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { setupMFA, verifyMFA } from "actions/session";
import { LoadingButton } from "@mui/lab";
import OTPInput from "react-otp-input";

const styles = () => {
  return {
    minHeight: "200px",
    "& .loader": {
      display: "flex",
      alignItems: "center",
    },
    "& .mfa-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& .mfa-code": {
        px: 2,
        py: 1,
        backgroundColor: "#004057",
        color: "white",
      },
    },

    "& .mui-field": {
      minHeight: "78px",
    },
  };
};

const SetupMFA = ({ onClose }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  const user = useSelector((state) => state.session?.user);
  const mfaSetupLoading = useSelector(
    (state) => state.session?.mfaSetupLoading
  );
  const mfaSetupCode = useSelector((state) => state.session?.mfaSetupCode);
  const mfaVerifyLoading = useSelector(
    (state) => state.session?.mfaVerifyLoading
  );

  useEffect(() => {
    if (user) {
      dispatch(
        setupMFA(user, (res) => {
          if (!res) {
            onClose();
          }
        })
      );
    }
  }, [user]);

  // Building QR value
  const env = process.env.REACT_APP_NODE_ENV;
  const appName = env === 'development' ? '[DEV] Energeia uSim SaaS' :
    env === 'test' ? '[TEST] Energeia uSim SaaS' :
      'Energeia uSim SaaS';
  const email = user?.attributes?.email || 'user@example.com';
  const issuer = 'Cognito';
  const baseURI = `otpauth://totp/${appName}:${email}`;
  const params = `?secret=${mfaSetupCode}&issuer=${issuer}`;
  const qrValue = baseURI + params;

  const onClickVerify = () => {
    dispatch(
      verifyMFA(user, otp, () => {
        onClose();
      })
    );
  };

  return (
    <Box sx={styles}>
      {mfaSetupLoading ? (
        <Box className="loader">
          <Loader show={true} />
        </Box>
      ) : (
        mfaSetupCode && (
          <Box className="mfa-section">
            <Typography variant="subtitle1" mb={3}>
              Please setup Multi Factor Authentication using the Microsoft
              Authenticator app.
            </Typography>
            <QRCodeSVG value={qrValue} />
            <Typography my={3} variant="subtitle1">
              Scan the above QR Code in your Microsoft Authenticator app.
            </Typography>

            <form>
              <Box mb={2}>
                <Typography variant="subtitle1" mb={1} align="center">
                  Enter the generated 6 digit code to verify the setup
                </Typography>
                <OTPInput
                  inputStyle="otpInputStyle"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus
                />
              </Box>

              <LoadingButton
                sx={{ mb: 2 }}
                onClick={onClickVerify}
                variant="contained"
                loading={mfaVerifyLoading}
                disabled={otp.length < 6}
                type="submit"
              >
                Verify Code
              </LoadingButton>
            </form>
          </Box>
        )
      )}
    </Box>
  );
};

export default SetupMFA;
