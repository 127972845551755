import { Modal } from "components";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { logout, confirmSignIn } from "actions/session";
import { LoadingButton } from "@mui/lab";
import OtpInput from "react-otp-input";

const styles = () => {
  return {
    minHeight: "200px",
    "& .loader": {
      display: "flex",
      alignItems: "center",
    },
    "& .mfa-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  };
};

const MFAOtpModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);

  const user = useSelector((state) => state.session?.user);
  const mfaVerifyLoading = useSelector(
    (state) => state.session?.mfaVerifyLoading
  );

  useEffect(() => {
    if (otpError && otp.length > 0) {
      setOtpError(false);
    }
  }, [otp]);

  useEffect(() => {
    if (
      user?.constructor?.name !== "CognitoUser" &&
      user?.constructor?.name !== "e"
    ) {
      dispatch(logout());
    }
  }, [user]);

  const onClickVerify = () => {
    dispatch(
      confirmSignIn(user, otp, (res) => {
        if (!res) {
          setOtp("");
          setOtpError(true);
        }
      })
    );
  };

  return (
    <Modal
      top="20%"
      title="Additional Security Verification Required"
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
      blurBackground={true}
    >
      <Box mt={4} sx={styles}>
        <Box className="mfa-section">
          <form>
            <Typography variant="h4">Verification Code</Typography>
            <Typography variant="body2" my={1.5}>
              {user?.challengeName === "SMS_MFA" && (
                <span>Enter the 6 digit verification code received.</span>
              )}
              {user?.challengeName === "SOFTWARE_TOKEN_MFA" && (
                <span>
                  Open the Microsoft Authenticator app and enter the generated 6
                  digit verification code.
                </span>
              )}
            </Typography>
            <OtpInput
              inputStyle="otpInputStyle"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />

            {otpError && (
              <Typography align="center" color="error" mt={1}>
                Verification Failed
              </Typography>
            )}

            <LoadingButton
              sx={{ mt: 3 }}
              onClick={onClickVerify}
              variant="contained"
              loading={mfaVerifyLoading}
              disabled={otp.length === 0}
              type="submit"
            >
              Verify Code
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default MFAOtpModal;
