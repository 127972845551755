import { getProjectDetailsWithoutLoading } from "actions/projects";
import { API } from "aws-amplify";

export const GENERATE_AGENT_DESIGN_REQUEST =
  "@@agentDesign/GENERATE_AGENT_DESIGN_REQUEST";
export const GENERATE_AGENT_DESIGN_SUCCESS =
  "@@agentDesign/GENERATE_AGENT_DESIGN_SUCCESS";
export const GENERATE_AGENT_DESIGN_FAILURE =
  "@@agentDesign/GENERATE_AGENT_DESIGN_FAILURE";

export function generateAgentDesign(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GENERATE_AGENT_DESIGN_REQUEST,
    });
    try {  // NOTE: Routes to {ENV}-usim-agentdesign in the Python backend
      let url = `/validation?project_id=${id}&organisation=${organisation}&step=7`;

      const response = await API.get("usimAPI", `${url}&only_statistics=true`);

      dispatch(getProjectDetailsWithoutLoading(id, organisation));

      dispatch({
        type: GENERATE_AGENT_DESIGN_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GENERATE_AGENT_DESIGN_FAILURE,
        payload: error,
      });
    }
    try { // NOTE: Routes to {ENV}-usim-agentdesign in the Python backend
      let url = `/validation?project_id=${id}&organisation=${organisation}&step=7`;

      dispatch({
        type: GENERATE_AGENT_DESIGN_REQUEST,
      });
      const response2 = await API.get("usimAPI", url);
      dispatch(getProjectDetailsWithoutLoading(id, organisation));
      dispatch({
        type: GENERATE_AGENT_DESIGN_SUCCESS,
        payload: response2,
      });
    } catch (error) {
      dispatch({
        type: GENERATE_AGENT_DESIGN_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_AGENT_DESIGN_LIST_REQUEST =
  "@@agentDesign/GET_AGENT_DESIGN_LIST_REQUEST";
export const GET_AGENT_DESIGN_LIST_SUCCESS =
  "@@agentDesign/GET_AGENT_DESIGN_LIST_SUCCESS";
export const GET_AGENT_DESIGN_LIST_FAILURE =
  "@@agentDesign/GET_AGENT_DESIGN_LIST_FAILURE";

export function getAgentDesignList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_AGENT_DESIGN_LIST_REQUEST,
    });
    try {
      let url = `/showdata?project_id=${id}&organisation=${organisation}&table_name=agent_stats_full`;

      const response = await API.get("usimAPI", url);

      dispatch({
        type: GET_AGENT_DESIGN_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENT_DESIGN_LIST_FAILURE,
        payload: error,
      });
    }
  };
}
