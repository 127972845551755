export const styles = (width = 400) => {
  let left = "25%";
  if (typeof width === "number") {
    left = `${(window.innerWidth - width) / 2}px`;
  }
  return {
    position: "absolute",
    top: "15%",
    left: left,
    // transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    padding: "20px",
    borderRadius: "8px",
    "&:focus-visible": {
      outline: "unset",
    },
  };
};

export const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  "& .MuiSvgIcon-root": {
    cursor: "pointer",
  },
};

export const iconWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "12px",
};
