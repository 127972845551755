export const options = {
  title: {
    text: "",
    style: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#1f497d",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    type: "datetime",
    categories: [],
    labels: {
      style: {
        fontWeight: 600,
        color: "#1f497d",
      },
    },
    showLastLabel: true,
  },
  yAxis: {
    gridLineColor: "#F0F3F4",
    title: {
      text: "",
    },
    labels: {
      style: {
        fontWeight: 600,
        color: "#1f497d",
      },
    },
  },
  legend: {
    align: "right",
    layout: "vertical",
    verticalAlign: "middle",
    itemMarginBottom: 10,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  time: {
    useUTC: false,
  },
  loading: {
    style: {
      opacity: 0.8,
    },
  },
  chart: {
    type: "line",
    height: 400,
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
};

export const areaChartOptions = {
  title: {
    text: "",
    style: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#1f497d",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    type: "datetime",
    categories: [],
    labels: {
      style: {
        fontWeight: 600,
        color: "#1f497d",
      },
    },
    showLastLabel: true,
  },
  yAxis: {
    gridLineColor: "#F0F3F4",
    title: {
      text: "",
    },
    labels: {
      style: {
        fontWeight: 600,
        color: "#1f497d",
      },
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  time: {
    useUTC: false,
  },
  loading: {
    style: {
      opacity: 0.8,
    },
  },
  chart: {
    type: "area",
    height: 400,
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
};
