import { getCsvColumnsList } from "utils/csv";

export const GET_CSV_COLUMNS_REQUEST = "@@csvColumns/GET_CSV_COLUMNS_REQUEST";
export const GET_CSV_COLUMNS_SUCCESS = "@@csvColumns/GET_CSV_COLUMNS_SUCCESS";
export const GET_CSV_COLUMNS_FAILURE = "@@csvColumns/GET_CSV_COLUMNS_FAILURE";

export function getCsvColumns(file) {
  return async (dispatch) => {
    dispatch({
      type: GET_CSV_COLUMNS_REQUEST,
    });
    try {
      const columns = await getCsvColumnsList(file);
      dispatch({
        type: GET_CSV_COLUMNS_SUCCESS,
        payload: columns,
      });
    } catch (error) {
      dispatch({
        type: GET_CSV_COLUMNS_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const CLEAR_CSV_COLUMNS_LIST = "@@csvColumns/CLEAR_CSV_COLUMNS_LIST";

export function clearCsvColumns() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_CSV_COLUMNS_LIST,
    });
  };
}
