import React, { useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./index.scss";
import { getInputComponent } from "./getInputComponent";

const FormField = (props) => {
  const [values, setValues] = useState({
    showPassword: false,
  });
  const { type, input, children, ...rest } = props;

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const showError = props.error && props.error !== "" && props.error !== null;
  const Component = getInputComponent(type);

  return (
    <div
      className={`mui-field ${
        props.variant === "standard" ? "standard-field" : ""
      }`}
    >
      {props.label && props.variant === "outlined" && (
        <div className="mui-field-label">{props.label}</div>
      )}
      <div className="mui-field-value">
        <Component
          {...props}
          {...input}
          {...rest}
          {...children}
          label={props.variant !== "outlined" ? props.label : ""}
          id={props.inputId}
          onChange={props.onChange}
          type={
            props.type === "password"
              ? values.showPassword
                ? "text"
                : "password"
              : props.type
          }
          onInput={(e) =>
            props.type === "number"
              ? (e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, props.maxNumberLength))
              : {}
          }
          InputProps={{
            endAdornment: props.type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: props.maxLength,
          }}
          helperText={showError ? props.error : props.helperText}
          error={!!showError}
          disabled={props.disabled}
          defaultValue={props.value}
        />
      </div>
    </div>
  );
};

export default FormField;
