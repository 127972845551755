import { toast } from "react-toastify";

const SUPPRESS_ERRORS = ["request failed", "service unavailable"];

export const uSimError = (error) => {
  console.log("error", error);
  const suppressError = SUPPRESS_ERRORS.find((it) =>
    error.toLowerCase().includes(it)
  );
  if (suppressError) {
    return null;
  }
  toast.error(error);
};
