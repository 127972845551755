import { MenuItem, FormControl, Select } from "@mui/material";

const selectStyles = (theme) => {
  return {
    padding: "0 12px",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.primary.main,
      paddingLeft: "0px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: theme.palette.primary.main,
    },
  };
};

const Dropdown = ({ options = [], value, name, onChange, disabled }) => {
  return (
    <FormControl size="small" className="select-dropdown">
      <Select
        value={value}
        onChange={onChange}
        sx={(theme) => selectStyles(theme)}
        name={name}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
