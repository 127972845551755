import {
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  CLEAR_PROJECT_DETAILS,
  POST_PROJECT_REQUEST,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAILURE,
  PUT_PROJECT_REQUEST,
  PUT_PROJECT_SUCCESS,
  PUT_PROJECT_FAILURE,
  SET_SHOW_PROJECT_SIDEBAR,
  CLONE_PROJECT_REQUEST,
  CLONE_PROJECT_SUCCESS,
  CLONE_PROJECT_FAILURE,
  CLEAR_SIM_READY,
} from "actions/projects";
import { isEmpty } from "lodash";
import { getProjectCompletion } from "utils/project";

const initialState = {
  loading: false,
  list: [],
  details: {},
  error: {},
  showProjectSidebar: true,
  previousProject: {},
  isSimReady: false,
};

export default function projects(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_LIST_REQUEST:
    case POST_PROJECT_REQUEST:
    case PUT_PROJECT_REQUEST:
    case CLONE_PROJECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }

    case GET_PROJECT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_PROJECT_DETAILS_SUCCESS: {
      const isSimReady =
        !isEmpty(state.previousProject) &&
        getProjectCompletion(payload) === 100 &&
        payload?.step_10_validation === "True" &&
        state.previousProject?.step_10_validation !== "True";

      return {
        ...state,
        loading: false,
        details: payload,
        previousProject: state.details,
        isSimReady,
      };
    }

    case CLEAR_PROJECT_DETAILS: {
      return {
        ...state,
        details: {},
        isSimReady: false,
      };
    }

    case POST_PROJECT_SUCCESS:
    case PUT_PROJECT_SUCCESS:
    case CLONE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PROJECT_LIST_FAILURE:
    case GET_PROJECT_DETAILS_FAILURE:
    case POST_PROJECT_FAILURE:
    case PUT_PROJECT_FAILURE:
    case CLONE_PROJECT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: {
          ...payload,
          message: payload?.message || "Something went wrong",
        },
      };
    }

    case SET_SHOW_PROJECT_SIDEBAR: {
      return {
        ...state,
        showProjectSidebar: payload,
      };
    }

    case CLEAR_SIM_READY: {
      return {
        ...state,
        isSimReady: false,
      };
    }

    default:
      return state;
  }
}
