import React from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { pageHeader } from "./styles";
import back from "images/icon/back.svg";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PageHeader = ({
  title,
  activeTab,
  onChangeTab,
  tabs = [],
  onClickBack = undefined,
  breadcrumbs = [],
}) => {
  const navigate = useNavigate();

  const onClickBreadcrumb = (route) => {
    if (!route) return;
    navigate(route);
  };

  return (
    <Box sx={(theme) => pageHeader(theme)}>
      <Box className="setting-breadcrumbs">
        {breadcrumbs?.map((item, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <KeyboardArrowRight />}
            <Typography
              variant={item.route ? "subtitle2" : "body2"}
              onClick={() => onClickBreadcrumb(item.route)}
              sx={{
                cursor: item.route ? "pointer" : "default",
              }}
            >
              {item.label}
            </Typography>
          </React.Fragment>
        ))}
      </Box>
      <Box className="setting-page-header-title">
        {onClickBack && (
          <img className="backicon" src={back} onClick={onClickBack} />
        )}
        <Typography variant="h3" fontWeight={600}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Tabs
          sx={{
            minHeight: "32px",
          }}
          value={activeTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          {tabs &&
            tabs.map((item, i) => (
              <Tab
                sx={{
                  padding: "12px 0",
                  minHeight: "32px",
                  marginRight: "24px",
                }}
                key={i}
                value={item.value}
                label={item.label}
              />
            ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default PageHeader;
