import { Box } from "@mui/material";

export const styles = (theme) => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "20px 40px 0 40px",
    backgroundColor: theme.palette.secondary.light,
  };
};

const ProjectPageContainer = ({ children }) => {
  return <Box sx={(theme) => styles(theme)}>{children}</Box>;
};

export default ProjectPageContainer;
