export const routes = [
  {
    label: "Scenarios",
    route: "scenarios",
    stepValidationKey: "step_1_validation",
    children: [
      {
        label: "Scenarios",
        route: "in-scenarios",
        inputKey: "input_scen_scenarios",
      },
      {
        label: "Customer Classes",
        route: "customer-classes",
        inputKey: "input_scen_customer_classes",
      },
    ],
  },
  {
    label: "Assets",
    route: "assets",
    stepValidationKey: "step_2_validation",
    children: [
      {
        label: "Asset Hierarchy",
        route: "asset-hierarchy",
        inputKey: "input_in_asset_classes",
        noInput: true,
      },
      {
        label: "Financials",
        route: "financials",
        inputKey: "input_in_networks",
      },
      {
        label: "Attributes",
        route: "attributes",
        inputKey: "input_in_assets",
      },
      {
        label: "Measurements",
        route: "measurements",
        inputKey: "input_in_asset_intervals_new",
      },
    ],
  },
  {
    label: "Technology",
    route: "technology",
    stepValidationKey: "step_3_validation",
    children: [
      {
        label: "Technologies",
        route: "technologies",
        inputKey: "input_in_technology",
      },
      {
        label: "Battery",
        route: "battery",
        inputKey: "input_in_battery",
      },
      {
        label: "Solar",
        route: "solar",
        inputKey: "input_in_solarpv",
      },
      {
        label: "Solar Profiles",
        route: "solar-profiles",
        inputKey: "input_in_solarprofile",
      },
      {
        label: "Tech Availability",
        route: "tech-availability",
        inputKey: "input_scen_scenarios_techs",
      },
      {
        label: "Rebate Curves",
        route: "rebate-curves",
        inputKey: "input_in_rebate_curves",
      },
      {
        label: "Cost Curves",
        route: "cost-curves",
        inputKey: "input_in_cost_curves",
      },
      {
        label: "Products",
        route: "products",
        inputKey: "input_in_technology_products",
        validateRequired: "step_3_validation",
        noInput: true,
      },
    ],
  },
  {
    label: "Appliance",
    route: "appliance",
    stepValidationKey: "step_4_validation",
    children: [
      {
        label: "Appliances",
        route: "appliances",
        inputKey: "input_in_appliance",
      },
      {
        label: "Prices",
        route: "prices",
        inputKey: "input_in_appliance_cost_curves",
      },
      {
        label: "Subload Magnitudes",
        route: "subload-magnitudes",
        inputKey: "input_in_subload_magnitude",
      },
      {
        label: "Subload Intervals",
        route: "subload-intervals",
        inputKey: "input_in_subload_intervals",
      },
      {
        label: "Products",
        route: "products",
        inputKey: "input_appliance_products",
        validateRequired: "step_4_validation",
        noInput: true,
      },
    ],
  },
  {
    label: "Tariff",
    route: "tariff",
    stepValidationKey: "step_5_validation",
    children: [
      {
        label: "Tariffs",
        route: "tariffs",
        inputKey: "input_in_tariffs",
      },
      {
        label: "Tariff Components",
        route: "tariff-components",
        inputKey: "input_in_components",
      },
      {
        label: "Default Tariffs",
        route: "default-tariffs",
        inputKey: "input_scen_tariffs_default",
      },
      {
        label: "Feed In Tariff Growth",
        route: "feed-in-tariff-growth",
        inputKey: "input_in_fit_growth",
      },
      {
        label: "Gas Tariffs",
        route: "gas-tariffs",
        inputKey: "input_in_gas_tariffs",
      },
      {
        label: "Gas Daily Block",
        route: "gas-daily-block",
        inputKey: "input_in_gas_daily_block",
      },
      {
        label: "Gas Decline Block",
        route: "gas-decl-block",
        inputKey: "input_in_gas_decl_block",
      },
    ],
  },
  {
    label: "Vehicle",
    route: "vehicle",
    stepValidationKey: "step_6_validation",
    children: [
      {
        label: "Vehicles",
        route: "vehicles",
        inputKey: "input_in_vehicle",
      },
      {
        label: "Cost Curves",
        route: "cost-curves",
        inputKey: "input_in_vehicle_cost_curves",
      },
      {
        label: "EV Intervals",
        route: "ev-intervals",
        inputKey: "input_in_ev_interval",
      },
      {
        label: "Model Availability",
        route: "model-availability",
        inputKey: "input_scen_ev_model_availability",
      },
      {
        label: "EV Tariff Uptake",
        route: "ev-tariff-uptake",
        inputKey: "input_scen_ev_tariff_uptake",
      },
      {
        label: "Fuel Cost",
        route: "fuel-cost",
        inputKey: "input_scen_fuel_cost",
      },
      {
        label: "Vehicle Stock",
        route: "vehicle-stock",
        inputKey: "input_in_vehicle_stock",
      },
      {
        label: "Charging Locations",
        route: "charging-locations",
        inputKey: "input_charging_locations",
      },
      {
        label: "Preferred Charging Location",
        route: "preferred-charging-location",
        inputKey: "input_preferred_charging_location",
      },
      {
        label: "Products",
        route: "products",
        inputKey: "input_vehicle_products",
        validateRequired: "step_6_validation",
        noInput: true,
      },
    ],
  },
  {
    label: "Agent",
    route: "agent",
    stepValidationKey: "step_8_validation",
    children: [
      {
        label: "Customer Attributes",
        route: "customer-attributes",
        inputKey: "input_customer_annual_consumption", // input_customer_attributes
      },
      {
        label: "Customer Consumption Bands",
        route: "customer-consumption-bands",
        inputKey: "input_customer_consumption_bands",
      },
      {
        label: "Agent Design",
        route: "agent-design",
        inputKey: "",
        inputRequired: [
          "input_customer_annual_consumption", // input_customer_attributes
          "input_customer_consumption_bands",
        ],
        stepValidationKey: "step_7_validation",
        noInput: true,
      },
      {
        label: "Customer Growth",
        route: "customerGrowth",
        inputKey: "input_in_customer_growth_yoy",
      },
      {
        label: "Customer Intervals",
        route: "customer-intervals",
        inputKey: "input_in_sample_intervals_new",
      },
      {
        label: "Customer Growth Results",
        route: "customer-growth-results",
        inputKey: "",
        validateRequired: "step_8_validation",
      },
    ],
  },
  {
    label: "Appliance Mix",
    route: "appliance-mix",
    children: [],
    standalone: true,
    stepValidationKey: "step_9_validation",
  },
  {
    label: "ROI Curves",
    route: "roi-curves",
    stepValidationKey: "step_10_validation",
    children: [],
    standalone: true,
  },
];

export const getRoutes = (route) => {
  return routes.find((it) => it.route === route);
};
