import { Box, Typography } from "@mui/material";
import { Modal } from "components";
import LoadingButton from "@mui/lab/LoadingButton";

const SimulationRunModal = ({ open, onClose }) => {
  return (
    <Box>
      <Modal
        title="Simulations Available to Run"
        open={open}
        onClose={onClose}
        width={600}
      >
        <Box>
          <Box>
            <Typography variant="subtitle1" mb={1}>
              Congratulations. All of your uploaded data is ready for uSim.
            </Typography>
            <Typography variant="subtitle1" mb={1}>
              Click on Manage Simulations to start a simulation.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              "& button": {
                width: "48%",
              },
              mt: 4,
            }}
          >
            <LoadingButton onClick={onClose} variant="contained" type="submit">
              Edit
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SimulationRunModal;
