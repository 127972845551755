import { API } from "aws-amplify";

export const GET_PRODUCTS_LIST_REQUEST =
  "@@applianceProducts/GET_PRODUCTS_LIST_REQUEST";
export const GET_PRODUCTS_LIST_SUCCESS =
  "@@applianceProducts/GET_PRODUCTS_LIST_SUCCESS";
export const GET_PRODUCTS_LIST_FAILURE =
  "@@applianceProducts/GET_PRODUCTS_LIST_FAILURE";

export function getProductsList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_PRODUCTS_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=appliance_products&organisation=${organisation}`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PRODUCTS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const GET_PRODUCTS_CHART_DATA_REQUEST =
  "@@applianceProducts/GET_PRODUCTS_CHART_DATA_REQUEST";
export const GET_PRODUCTS_CHART_DATA_SUCCESS =
  "@@applianceProducts/GET_PRODUCTS_CHART_DATA_SUCCESS";
export const GET_PRODUCTS_CHART_DATA_FAILURE =
  "@@applianceProducts/GET_PRODUCTS_CHART_DATA_FAILURE";

export function getProductsChartData(
  id = "",
  organisation,
  applianceProductId
) {
  return async (dispatch) => {
    if (!(id && organisation && applianceProductId)) return;
    dispatch({
      type: GET_PRODUCTS_CHART_DATA_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation && applianceProductId) {
        url = `${url}?project_id=${id}&table_name=appliance_products&organisation=${organisation}&appliance_product=${applianceProductId}`;
      }

      const response = await API.get("usimAPI", url);
      console.log("response", response);
      dispatch({
        type: GET_PRODUCTS_CHART_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_CHART_DATA_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
