import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import chevronLeft from "images/icon/chevronLeft.svg";
import chevronRight from "images/icon/chevronRight.svg";
import { SessionLogo, EnergeiaCircle } from "components";
import { menuList } from "constants/menus";
import { useNavigate, useLocation } from "react-router-dom";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    borderRightColor: "#D6DDEA",
    color: "#383D4B",
    backgroundColor: "#E9ECF5",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#F6F7FC",
    color: "#2B7FE0",
  },
  "& .active-menu": {
    backgroundColor: "#F6F7FC",
    color: "#2B7FE0",
    fontWeight: 500,
  },
}));

const Sidebar = ({ onOpenChange }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    onOpenChange(open);
  }, [open]);

  const onClick = (menu) => {
    navigate(menu.route);
  };

  const checkActive = (menu) => {
    let className = "";
    if (location.pathname.includes(menu.route)) {
      className = "active-menu";
    }
    return className;
  };

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <IconButton
        sx={{
          position: "absolute",
          top: "8px",
          right: "-24px",
          zIndex: "9999",
        }}
        onClick={() => setOpen(!open)}
      >
        <img src={open ? chevronLeft : chevronRight} />
      </IconButton>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open ? <SessionLogo width="200" /> : <EnergeiaCircle width="35px" />}
        </DrawerHeader>
        <Divider />
        <List>
          {menuList.map((item, i) => (
            <Box key={i} mx={2}>
              {open && (
                <Typography variant="overline" mb={2} color="#7D818B">
                  {item.label}
                </Typography>
              )}
              {item.menus.map((menu, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => onClick(menu)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mb: "1px",
                    }}
                    className={checkActive(menu)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider sx={{ mt: 0.5 }} />
            </Box>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
