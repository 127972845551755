export const projectHeaderTab = {
  upload: "upload-data",
  scenarios: "define-scenarios",
  simulations: "manage-simulations",
};

export const projectHeaderTabs = [
  { value: projectHeaderTab.upload, label: "Upload Data" },
  // {
  //   value: projectHeaderTab.scenarios,
  //   label: "2. Define Scenarios",
  //   disabled: true,
  //   validation: true,
  // },
  {
    value: projectHeaderTab.simulations,
    label: "Manage Simulations",
    validation: true,
  },
];

export const getTabRoute = (tab, id) => {
  const item = projectHeaderTabs.find((it) => it.value === tab);
  const extraProp = tab === projectHeaderTab.upload ? "/scenarios" : "";
  const route = `/project/${item.value}/${id}${extraProp}`;
  return route;
};
