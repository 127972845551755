import { API } from "aws-amplify";

export const GET_PRODUCTS_LIST_REQUEST = "@@products/GET_PRODUCTS_LIST_REQUEST";
export const GET_PRODUCTS_LIST_SUCCESS = "@@products/GET_PRODUCTS_LIST_SUCCESS";
export const GET_PRODUCTS_LIST_FAILURE = "@@products/GET_PRODUCTS_LIST_FAILURE";

export function getProductsList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_PRODUCTS_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=technology_products&organisation=${organisation}`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PRODUCTS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const GET_PRODUCTS_CHART_DATA_REQUEST =
  "@@products/GET_PRODUCTS_CHART_DATA_REQUEST";
export const GET_PRODUCTS_CHART_DATA_SUCCESS =
  "@@products/GET_PRODUCTS_CHART_DATA_SUCCESS";
export const GET_PRODUCTS_CHART_DATA_FAILURE =
  "@@products/GET_PRODUCTS_CHART_DATA_FAILURE";

export function getProductsChartData(id = "", organisation, techId) {
  return async (dispatch) => {
    if (!(id && organisation && techId)) return;
    dispatch({
      type: GET_PRODUCTS_CHART_DATA_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation && techId) {
        url = `${url}?project_id=${id}&table_name=technology_products&organisation=${organisation}&tech_product=${techId}`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PRODUCTS_CHART_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_CHART_DATA_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
