import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const styles = (value = 0) => {
  // let width = 0;
  // const item = document.getElementById("progress-bar-section");
  // if (item) {
  //   width = (value / 100) * item.clientWidth;
  // }
  return {
    width: "100%",
    height: "6px",
    position: "relative",
    borderRadius: "3px",
    backgroundColor: "#E9ECF5",
    "& .progress-bar": {
      width: `${value}%`,
      height: "6px",
      borderRadius: "3px",
      backgroundColor: "#3190FF",
    },
  };
};

const ProgressBar = ({ value = 0 }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <Box id="progress-bar-section" sx={() => styles(progress)}>
      <Box className="progress-bar"></Box>
    </Box>
  );
};

export default ProgressBar;
