import { Switch, Box, Typography } from "@mui/material";

const ToggleSwitch = ({ label, value, name, onChange, disabled }) => {
  const handleChange = (e) => {
    const event = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: e.target.checked,
      },
    };
    onChange(event);
  };
  return (
    <Box
      className="toggle-switch"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="body2" fontWeight={500}>
        {label}
      </Typography>
      <Switch
        name={name}
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </Box>
  );
};

export default ToggleSwitch;
