import {
  GET_ASSET_HIERARCHY_LIST_REQUEST,
  GET_ASSET_HIERARCHY_LIST_SUCCESS,
  GET_ASSET_HIERARCHY_LIST_FAILURE,
} from "actions/assets/assetHierarchy";

const initialState = {
  loading: false,
  list: [],
};

export default function assetHierarchy(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ASSET_HIERARCHY_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ASSET_HIERARCHY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_ASSET_HIERARCHY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    default:
      return state;
  }
}
