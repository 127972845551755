import {
  CHECK_VALIDATION_STATUS_REQUEST,
  CHECK_VALIDATION_STATUS_SUCCESS,
  CHECK_VALIDATION_STATUS_FAILURE,
  CLEAR_VALIDATION_STATUS,
  FINAL_STEP_VALIDATION_REQUEST,
  FINAL_STEP_VALIDATION_SUCCESS,
  FINAL_STEP_VALIDATION_FAILURE,
  GET_LAST_INPUT_ERROR_SUCCESS,
  PUT_LAST_INPUT_ERROR_SUCCESS,
} from "actions/validation";
import { uSimError } from "utils/override";

const initialState = {
  loading: false,
  validationStatus: "",
  stepValidationLoading: false,
  projectId: "",
  step_1_validation: "",
  step_2_validation: "",
  step_3_validation: "",
  step_4_validation: "",
  step_5_validation: "",
  step_6_validation: "",
  step_7_validation: "",
  step_8_validation: "",
  step_9_validation: "",
  step_10_validation: "",
  lastInputError: "",
};

export default function validation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHECK_VALIDATION_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case CHECK_VALIDATION_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case CHECK_VALIDATION_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        // ...payload,
      };
    }

    case CLEAR_VALIDATION_STATUS: {
      return {
        ...state,
        ...initialState,
      };
    }

    case FINAL_STEP_VALIDATION_REQUEST: {
      return {
        ...state,
        stepValidationLoading: true,
      };
    }

    case FINAL_STEP_VALIDATION_SUCCESS: {
      return {
        ...state,
        stepValidationLoading: false,
      };
    }

    case FINAL_STEP_VALIDATION_FAILURE: {
      uSimError(payload?.message || "");
      return {
        ...state,
        stepValidationLoading: false,
      };
    }

    case GET_LAST_INPUT_ERROR_SUCCESS:
    case PUT_LAST_INPUT_ERROR_SUCCESS: {
      return {
        ...state,
        lastInputError: payload?.message ?? "",
      };
    }

    default:
      return state;
  }
}
