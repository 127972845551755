import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { finalStepValidation } from "actions/validation";
import { VALIDATION_STEPS } from "constants/validation";
import { getProjectDetailsWithoutLoading } from "actions/projects";
import { isEqual } from "lodash";

const ValidateButton = ({ stepValidation = "", mt = 4, mr = 10, mb = 0 }) => {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);

  const project = useSelector((state) => state.projects?.details);
  const projectLoading = useSelector((state) => state.projects?.loading);
  const validation = useSelector((state) => state?.validation);
  const previousProject = useSelector(
    (state) => state.projects?.previousProject
  );

  useEffect(() => {
    setValid(!projectLoading);
    if (project?.item) {
      const index = VALIDATION_STEPS.findIndex(
        (it) => it.key === stepValidation
      );
      const keys = VALIDATION_STEPS.filter((it, i) => i < index).map(
        (it) => it.key
      );
      if (stepValidation === "step_1_validation") {
        setValid(project?.input_scen_scenarios);
      } else {
        let isValid = true;
        keys.forEach((it) => {
          isValid = isValid && project[it] === "True";
        });
        setValid(isValid);
      }
    }
  }, [stepValidation, project]);

  useEffect(() => {
    if (validation) {
      setLoading(validation?.stepValidationLoading);
    }
  }, [stepValidation, validation]);

  useEffect(() => {
    if (!isEqual(project, previousProject)) {
      dispatch(
        getProjectDetailsWithoutLoading(project?.item, project.organisation)
      );
    }

    const interval = setInterval(() => {
      if (validating && !["True", ""].includes(project[stepValidation])) {
        dispatch(
          getProjectDetailsWithoutLoading(project?.item, project.organisation)
        );
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [validating, stepValidation, project]);

  const onClickValidate = () => {
    setValidating(true);
    const step = VALIDATION_STEPS.find((it) => it.key === stepValidation);
    if (step && step.apiUrl) {
      dispatch(finalStepValidation(project.item, project.organisation, step));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
      mr={mr}
      mt={mt}
      mb={mb}
    >
      <LoadingButton
        onClick={onClickValidate}
        variant="contained"
        disabled={!valid}
        loading={loading}
      >
        {loading ? "Validating Data" : "Validate Data"}
      </LoadingButton>
    </Box>
  );
};

export default ValidateButton;
