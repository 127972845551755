export const sessionContainer = (theme) => {
  return {
    padding: "0 10px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#E5E5E5",
    },
  };
};
