import { useState, Fragment } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import back from "images/icon/back.svg";
import {
  KeyboardArrowRight,
  FileDownloadOutlined,
  FileUploadOutlined,
  MoreHoriz,
  Loop,
  Refresh,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { VALIDATION_MESSAGE } from "constants/validation";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

const styles = (theme) => {
  return {
    marginBottom: "12px",
    backgroundColor: theme.palette.secondary.light,
    "& .project-page-header-title": {
      display: "flex",
      alignItems: "center",
      "& .backicon": {
        marginRight: "8px",
        cursor: "pointer",
      },
    },
    "& .top-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "12px",
      "& .validation-message": {
        display: "flex",
        alignItems: "center",
      },
    },
    "& .project-breadcrumbs": {
      display: "flex",
      alignItems: "center",
    },
    "& .title-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .child-container": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "8px",
        ml: 2,
        "& .child-container-item": {
          // marginLeft: "16px",
        },
      },
    },
    "& .ready-to-start": {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#DDEBFF",
      color: "#2B7FE0",
      padding: "4px 12px",
      borderRadius: "7px",
      mx: "8px",
    },
  };
};

const listStyles = () => {
  return {
    width: "200px",
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#F6F7FC",
      color: "#2B7FE0",
      borderRadius: "4px",
    },
  };
};

const ProjectSubHeader = ({
  title,
  description = "",
  onClickBack = undefined,
  breadcrumbs = [],
  onClickDownload = undefined,
  onClickUpload = undefined,
  onClickRefresh = undefined,
  downloadLoading = false,
  onClickDelete = undefined,
  validationMessage = "",
  uploadTitle = "Upload CSV",
  onClickGenerate = undefined,
  generateTitle = "Generate Design",
  generateLoading = false,
  generateDisabled = false,
  onClickExtraDownload = undefined,
  extraDownloadLabel = "",
  extraDownloadLoading = false,
  extraDownloadDisabled = false,
  stepValidation = "",
  showValidation = false,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const project = useSelector((state) => state.projects?.details);

  const openActionPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionPopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "action-popover" : undefined;

  const onClickBreadcrumb = (route) => {
    if (!route) return;
    navigate(route);
  };

  const isValidating =
    !isEmpty(stepValidation) &&
    project &&
    project[stepValidation] &&
    !["True", "False"].includes(project[stepValidation]);

  return (
    <Box sx={(theme) => styles(theme)}>
      <Box className="top-section">
        <Box className="project-breadcrumbs">
          {breadcrumbs?.map((item, i) => (
            <Fragment key={i}>
              {i !== 0 && <KeyboardArrowRight />}
              <Typography
                variant={item.route ? "subtitle2" : "body2"}
                onClick={() => onClickBreadcrumb(item.route)}
                sx={{
                  cursor: item.route ? "pointer" : "default",
                }}
              >
                {item.label}
              </Typography>
            </Fragment>
          ))}
        </Box>

        {validationMessage === VALIDATION_MESSAGE && (
          <Box className="validation-message">
            <Loop className="rotateIcon" />
            <Typography>{validationMessage}</Typography>
          </Box>
        )}
      </Box>
      <Box className="title-section">
        <Box className="project-page-header-title">
          {onClickBack && (
            <img className="backicon" src={back} onClick={onClickBack} />
          )}
          <Typography variant="h3" fontWeight={600}>
            {title}
          </Typography>

          {isValidating && showValidation && (
            <Box className="ready-to-start">
              <Loop className="rotateIcon" />
              <Typography variant="body2" fontWeight={500}>
                {project[stepValidation]}
              </Typography>
            </Box>
          )}
        </Box>

        <Box className="child-container">
          {onClickDelete && (
            <IconButton
              className="child-container-item"
              color="primary"
              onClick={openActionPopover}
            >
              <MoreHoriz />
            </IconButton>
          )}
          {onClickRefresh && (
            <Button
              className="child-container-item"
              onClick={onClickRefresh}
              variant="outlined"
              startIcon={<Refresh />}
            >
              Refresh Data
            </Button>
          )}
          {onClickDownload && (
            <LoadingButton
              loading={downloadLoading}
              className="child-container-item"
              onClick={onClickDownload}
              variant="outlined"
              startIcon={<FileDownloadOutlined />}
            >
              Download Template
            </LoadingButton>
          )}
          {onClickExtraDownload && (
            <LoadingButton
              loading={extraDownloadLoading}
              className="child-container-item"
              onClick={onClickExtraDownload}
              variant="outlined"
              startIcon={<FileDownloadOutlined />}
              disabled={extraDownloadDisabled}
            >
              {extraDownloadLabel}
            </LoadingButton>
          )}
          {onClickUpload && (
            <Button
              className="child-container-item"
              onClick={onClickUpload}
              variant="outlined"
              startIcon={<FileUploadOutlined />}
            >
              {uploadTitle}
            </Button>
          )}
          {onClickGenerate && (
            <LoadingButton
              className="child-container-item"
              onClick={onClickGenerate}
              variant="contained"
              loading={generateLoading}
              disabled={generateDisabled}
            >
              {generateTitle}
            </LoadingButton>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closeActionPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List sx={listStyles}>
              {onClickDelete && (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => {
                      closeActionPopover();
                      setOpenDeleteConfirmation(true);
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mb: "1px",
                    }}
                  >
                    <ListItemText
                      primary="Delete"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Popover>
          {openDeleteConfirmation && (
            <DeleteConfirmationDialog
              open={openDeleteConfirmation}
              onClose={() => setOpenDeleteConfirmation(false)}
              onClickDelete={onClickDelete}
            />
          )}
        </Box>
      </Box>
      {description && (
        <Box mt={1} ml={onClickBack ? 5 : 0}>
          <Typography variant="body2">{description}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProjectSubHeader;
