import { compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import thunk from "redux-thunk";
import rootReducer from "../reducers";
import createMiddleware from "./middleware";

const configureStore = (preloadedState) => {
  const history = createBrowserHistory();

  const persistConfig = {
    key: "energeia-persist",
    storage: storage,
    stateReconciler: hardSet,
    whitelist: ["session"], // which reducer want to store
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    compose(createMiddleware()),
    preloadedState
  );
  const persistor = persistStore(store);
  // window.addEventListener('storage', crossBrowserListener(store, persistConfig));
  return { history, persistor, store };
};

export default configureStore;
