import {
  GET_CUSTOMER_GROWTH_RESULT_LIST_REQUEST,
  GET_CUSTOMER_GROWTH_RESULT_LIST_SUCCESS,
  GET_CUSTOMER_GROWTH_RESULT_LIST_FAILURE,
} from "actions/agent/customerGrowthResult";

const initialChartItem = {
  data: [],
  name: "Customer Growth Result",
};

const initialChartData = [initialChartItem];

const initialState = {
  loading: false,
  chartData: initialChartData,
};

export default function customerGrowthResult(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMER_GROWTH_RESULT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CUSTOMER_GROWTH_RESULT_LIST_SUCCESS: {
      const chartItem = initialChartItem;
      chartItem.data = payload.map((it) => {
        const item = [];
        item.push(it.YEAR);
        item.push(it.TotalCustomers);
        return item;
      });
      return {
        ...state,
        loading: false,
        chartData: [chartItem],
      };
    }

    case GET_CUSTOMER_GROWTH_RESULT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        chartData: initialChartData,
      };
    }

    default:
      return state;
  }
}
