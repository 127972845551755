export const pageHeader = (theme) => {
  return {
    minHeight: "100px",
    paddingTop: "20px",
    paddingLeft: "40px",
    backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    "& .setting-page-header-title": {
      display: "flex",
      alignItems: "center",
      "& .backicon": {
        marginRight: "8px",
        cursor: "pointer",
      },
    },
    "& .setting-breadcrumbs": {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
  };
};

export const pageContainer = () => {
  return {
    height: "100%",
    width: "100%",
  };
};

export const filterStyles = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "16px",
    "& .filter-inputs": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& .MuiTextField-root": {
        width: "300px",
      },
      "& .select-dropdown": {
        width: "160px",
        margin: "0 8px",
      },
    },
  };
};

export const pageContents = (theme) => {
  return {
    height: "100%",
    width: "100%",
    padding: "20px 40px 0 40px",
    backgroundColor: theme.palette.secondary.light,
  };
};
