import { TextField } from "@mui/material";
import Checkbox from "./CheckBox";
import Dropdown from "./Dropdown";
import ToggleSwitch from "./ToggleSwitch";

export const getInputComponent = (type) => {
  switch (type) {
    case "checkbox":
      return Checkbox;
    case "select":
      return Dropdown;
    case "toggleswitch":
      return ToggleSwitch;
    default:
      return TextField;
  }
};
