import {
  START_SIMULATIONS_REQUEST,
  START_SIMULATIONS_SUCCESS,
  START_SIMULATIONS_FAILURE,
  STOP_SIMULATIONS_REQUEST,
  STOP_SIMULATIONS_SUCCESS,
  STOP_SIMULATIONS_FAILURE,
  ADD_SCENARIO_REQUEST,
  ADD_SCENARIO_SUCCESS,
  ADD_SCENARIO_FAILURE,
  GET_DOWNLOAD_RESULTS_REQUEST,
  GET_DOWNLOAD_RESULTS_SUCCESS,
  GET_DOWNLOAD_RESULTS_FAILURE,
  REFRESH_SIMULATIONS_RUNTIME_REQUEST,
  REFRESH_SIMULATIONS_RUNTIME_SUCCESS,
  REFRESH_SIMULATIONS_RUNTIME_FAILURE,
} from "actions/simulations";

const initialState = {
  loading: false,
  downloadLoading: false,
  refreshTimeLoading: false,
};

export default function simulations(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case START_SIMULATIONS_REQUEST:
    case STOP_SIMULATIONS_REQUEST:
    case ADD_SCENARIO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case START_SIMULATIONS_SUCCESS:
    case STOP_SIMULATIONS_SUCCESS:
    case ADD_SCENARIO_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case START_SIMULATIONS_FAILURE:
    case STOP_SIMULATIONS_FAILURE:
    case ADD_SCENARIO_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DOWNLOAD_RESULTS_REQUEST: {
      return {
        ...state,
        downloadLoading: true,
      };
    }

    case GET_DOWNLOAD_RESULTS_SUCCESS: {
      return {
        ...state,
        downloadLoading: false,
      };
    }

    case GET_DOWNLOAD_RESULTS_FAILURE: {
      return {
        ...state,
        downloadLoading: false,
      };
    }

    case REFRESH_SIMULATIONS_RUNTIME_REQUEST: {
      return {
        ...state,
        refreshTimeLoading: true,
      };
    }

    case REFRESH_SIMULATIONS_RUNTIME_SUCCESS: {
      return {
        ...state,
        refreshTimeLoading: false,
      };
    }

    case REFRESH_SIMULATIONS_RUNTIME_FAILURE: {
      return {
        ...state,
        refreshTimeLoading: false,
      };
    }

    default:
      return state;
  }
}
