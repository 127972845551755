import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onClickDelete,
  data = "data",
  name = "",
}) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          sx={{
            textTransform: "capitalize",
          }}
        >
          Delete {data}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {data}{name}? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClickDelete();
              onClose();
            }}
            sx={{ mr: 1 }}
          >
            Delete
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationDialog;
