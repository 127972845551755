import {
  GET_CSV_COLUMNS_REQUEST,
  GET_CSV_COLUMNS_SUCCESS,
  GET_CSV_COLUMNS_FAILURE,
  CLEAR_CSV_COLUMNS_LIST,
} from "actions/assets/csvColumns";
import { uSimError } from "utils/override";

const initialState = {
  csvColumnsLoading: false,
  csvColumnsList: [],
};

export default function csvColumns(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CSV_COLUMNS_REQUEST: {
      return {
        ...state,
        csvColumnsLoading: true,
      };
    }

    case GET_CSV_COLUMNS_SUCCESS: {
      return {
        ...state,
        csvColumnsLoading: false,
        csvColumnsList: payload,
      };
    }

    case GET_CSV_COLUMNS_FAILURE: {
      uSimError(payload?.message || "Something went wrong");
      return {
        ...state,
        csvColumnsLoading: false,
      };
    }

    case CLEAR_CSV_COLUMNS_LIST: {
      return {
        ...state,
        csvColumnsList: [],
      };
    }

    default:
      return state;
  }
}
