import { API } from "aws-amplify";

export const GET_CUSTOMER_GROWTH_RESULT_LIST_REQUEST =
  "@@customerGrowth/GET_CUSTOMER_GROWTH_RESULT_LIST_REQUEST";
export const GET_CUSTOMER_GROWTH_RESULT_LIST_SUCCESS =
  "@@customerGrowth/GET_CUSTOMER_GROWTH_RESULT_LIST_SUCCESS";
export const GET_CUSTOMER_GROWTH_RESULT_LIST_FAILURE =
  "@@customerGrowth/GET_CUSTOMER_GROWTH_RESULT_LIST_FAILURE";

export function getCustomerGrowthResultList(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_CUSTOMER_GROWTH_RESULT_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=tot_cust_forecast&organisation=${organisation}`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_CUSTOMER_GROWTH_RESULT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_GROWTH_RESULT_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
