export const headerStyle = (theme) => {
  return {
    height: "90px",
    backgroundColor: theme.palette.primary.lighter,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .flexBox": {
      display: "flex",
      alignItems: "center",
      "& .space": {
        marginRight: "32px",
      },
      "& .MuiBox-root": {
        marginBottom: 0,
        marginRight: "24px",
      },
      "& .MuiInputBase-colorPrimary": {
        backgroundColor: "white",
        width: "240px",
      },
      "& .signOut": {
        border: "unset",
        backgroundColor: "white",
      },
    },
  };
};

export const footerStyle = (theme) => {
  const { palette } = theme;
  return {
    height: "64px",
    borderTop: `2px solid ${palette.primary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: "8px",
    "& .footerItem": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "70px",
    },
    "& .active": {
      opacity: "0.6",
    },
  };
};

export const contentStyle = (width = "100%") => {
  return {
    width: width,
  };
};

export const sidebarStyle = (theme) => {
  return {
    width: "108px",
    backgroundColor: "#F0F3F4",
    padding: "20px 8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .sidebarItem": {
      height: "68px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      cursor: "pointer",
    },
    "& .active": {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      "& .MuiTypography-root": {
        opacity: "0.8",
      },
      "& svg": {
        opacity: "0.8",
      },
    },
  };
};
