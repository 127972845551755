export const sessionLogo = () => {
  return {
    display: "flex",
    alignItems: "center",
    width: "300px",
  };
};

export const headerLogo = () => {
  return {
    "& img": {
      height: "72px",
    },
  };
};
