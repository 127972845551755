import { CircularProgress, Box } from "@mui/material";

const fullPageStyle = () => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
};

const Loader = ({ show = false, fullPage = true }) => {
  if (!show) return null;
  return (
    <Box sx={fullPage ? fullPageStyle() : {}}>
      <CircularProgress size={80} />
    </Box>
  );
};

export default Loader;
