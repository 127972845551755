import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILURE,
  CLEAR_USER_DETAILS,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  PUT_USER_REQUEST,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
} from "actions/user";

import { uSimError } from "utils/override";

const initialState = {
  loading: false,
  list: [],
  details: {},
  error: {},
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LIST_REQUEST:
    case POST_USER_REQUEST:
    case PUT_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }

    case USER_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        details: {},
      };
    }

    case USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it) => {
          return {
            ...it,
            name: `${it?.first_name} ${it?.last_name}`,
          };
        }),
      };
    }

    case USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case CLEAR_USER_DETAILS: {
      return {
        ...state,
        details: {},
      };
    }

    case POST_USER_SUCCESS:
    case PUT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case USER_LIST_FAILURE:
    case USER_DETAILS_FAILURE:
    case POST_USER_FAILURE:
    case PUT_USER_FAILURE: {
      uSimError(payload?.message || "Something went wrong");
      return {
        ...state,
        loading: false,
        error: {
          ...payload,
          message: payload?.message || "Something went wrong",
        },
      };
    }

    default:
      return state;
  }
}
