import {
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_CHART_DATA_REQUEST,
  GET_PRODUCTS_CHART_DATA_SUCCESS,
  GET_PRODUCTS_CHART_DATA_FAILURE,
} from "actions/vehicle/vehicleProducts";

const initialChartItem = {
  y: "",
  timestamp: "",
  name: "",
  data: [],
};

const initialChartData = {
  modelInfrastructure: [],
  costs: [],
  chargerLoadProfiles: [],
};

const initialState = {
  loading: false,
  uploading: false,
  list: [],
  chartData: initialChartData,
};

const getChartItem = (payload, key) => {
  const item = payload[key];
  let objKey = "";
  if (item) {
    Object.keys(item).forEach((it, i) => {
      if (i === 0) {
        objKey = it;
      }
    });

    return item[objKey];
  } else {
    return undefined;
  }
};

const getChargersLoadProfiles = (payload) => {
  const list = [];
  const chargers = payload["chargers"];
  const keys = Object.keys(chargers);
  keys.forEach((key) => {
    const item = chargers[key];
    if (item) {
      const data = [];
      item["kwh_values"].forEach((it, i) => {
        const citem = [];
        citem.push(item.hours[i]);
        citem.push(it);
        data.push(citem);
      });

      list.push({
        ...initialChartItem,
        y: data,
        timestamp: item.hours,
        name: key,
        data: data,
      });
    }
  });

  return list;
};

const getCosts = (payload) => {
  const item = getChartItem(payload, "costs");
  if (item) {
    const list = [];
    const cost_type_data = item["cost_type_data"];
    Object.keys(cost_type_data).forEach((it) => {
      const data = [];
      cost_type_data[it].forEach((cit, i) => {
        const citem = [];
        citem.push(item.YEARS[i]);
        citem.push(cit);
        data.push(citem);
      });
      list.push({
        ...initialChartItem,
        y: data,
        timestamp: item.YEARS,
        name: it,
        data: data,
      });
    });

    return list;
  } else {
    return [];
  }
};

const getModelInfrastructure = (payload) => {
  const item = getChartItem(payload, "models");
  if (item) {
    const list = [];
    const cost_type_data = item["model_type_data"];
    Object.keys(cost_type_data).forEach((it) => {
      const data = [];
      cost_type_data[it].forEach((cit, i) => {
        const citem = [];
        citem.push(item.YEARS[i]);
        citem.push(cit);
        data.push(citem);
      });
      list.push({
        ...initialChartItem,
        y: data,
        timestamp: item.YEARS,
        name: it,
        data: data,
      });
    });

    return list;
  } else {
    return [];
  }
};

export default function vehicleProducts(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
            costs: it.VEHICLE_ID,
            modelInfrastructure: it.VEHICLE_ID,
            electric: it["Electric?"],
          };
        }),
      };
    }

    case GET_PRODUCTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case GET_PRODUCTS_CHART_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        chartData: initialChartData,
      };
    }

    case GET_PRODUCTS_CHART_DATA_SUCCESS: {
      const modelInfrastructure = getModelInfrastructure(payload) || [];
      const costs = getCosts(payload) || [];

      const chargerLoadProfiles = getChargersLoadProfiles(payload) || [];

      return {
        ...state,
        loading: false,
        chartData: {
          ...payload,
          modelInfrastructure,
          costs,
          chargerLoadProfiles,
        },
      };
    }

    case GET_PRODUCTS_CHART_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        chartData: initialChartData,
      };
    }

    default:
      return state;
  }
}
