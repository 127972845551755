import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { routes } from "constants/projectRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setShowProjectSidebar } from "actions/projects";

const useProjectSidebar = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const showProjectSidebar = useSelector(
    (state) => state.projects?.showProjectSidebar
  );

  useEffect(() => {
    const list = location.pathname.split(id);
    if (list.length > 1) {
      const tokens = list[1].slice(1).split("/");
      const lastItem = tokens[tokens.length - 1];

      const show = routes.filter((item) => item.route === lastItem).length > 0;
      if (showProjectSidebar !== show) {
        dispatch(setShowProjectSidebar(show));
      }

      // const item = routes.find((item) => item.route === lastItem);
      // if (item && item?.standalone === true) {
      //   dispatch(setShowProjectSidebar(false));
      // }
    }
  }, [location, id]);

  return [];
};

export default useProjectSidebar;
