import { isNil } from "lodash";
import Papa from "papaparse";

export const convertAndDownload = (list, filename = "sample_data") => {
  if (list.length > 0) {
    const replacer = (key, value) => (isNil(value) ? "" : value);
    const header = Object.keys(list[0]);
    const csv = [
      header.join(","),
      ...list.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);

    link.click();
  }
};

export const getCsvColumnsList = (file) => {
  return new Promise((resolve) => {
    if (file.type !== "text/csv") {
      resolve([]);
    } else {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const list = results.data;
          if (list.length > 0) {
            const heading = Object.keys(list[0]);
            resolve(heading);
          } else {
            resolve([]);
          }
        },
      });
    }
  });
};

export const encodeCsvData = (file) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const list = results.data;
        if (list.length > 0) {
          const heading = Object.keys(list[0]).join(",") + "\r\n";
          const body = list.map((j) => Object.values(j).join(",")).join("\r\n");
          const encodedString = window.btoa(`${heading}${body}`);
          resolve(encodedString);
        } else {
          resolve("");
        }
      },
    });
  });
};
