export const VALIDATION_MESSAGE = "Still Validating the Data";

export const VALIDATION_STEPS = [
  {
    key: "step_1_validation",
    step: 1,
    apiUrl: "validation",
  },
  {
    key: "step_2_validation",
    step: 2,
    apiUrl: "validation",
  },
  {
    key: "step_3_validation",
    step: 3,
    apiUrl: "validation",
  },
  {
    key: "step_4_validation",
    step: 4,
    apiUrl: "validation",
  },
  {
    key: "step_5_validation",
    step: 5,
    apiUrl: "validation",
  },
  {
    key: "step_6_validation",
    step: 6,
    apiUrl: "validation",
  },
  {
    key: "step_7_validation",
    step: 7,
    apiUrl: "validation",
  },
  {
    key: "step_8_validation",
    step: 8,
    apiUrl: "validation", // NOTE: Routes to {ENV}-usim-agentassetmappings in the Python backend
  },
  {
    key: "step_9_validation",
    step: 9,
    apiUrl: "validation", // NOTE: Routes to {ENV}-usim-agentappliances in the Python backend
  },
  {
    key: "step_10_validation",
    step: 10,
    apiUrl: "validation",
  },
];

export const getStep = (key) => {
  return VALIDATION_STEPS.find((it) => it.key === key);
};
