import { API } from "aws-amplify";
import { getProjectDetails } from "./projects";

export const START_SIMULATIONS_REQUEST =
  "@@simulations/START_SIMULATIONS_REQUEST";
export const START_SIMULATIONS_SUCCESS =
  "@@simulations/START_SIMULATIONS_SUCCESS";
export const START_SIMULATIONS_FAILURE =
  "@@simulations/START_SIMULATIONS_FAILURE";

export function startSimulations(id = "", organisation = "") {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: START_SIMULATIONS_REQUEST,
    });
    try {
      let url = "/run";
      if (organisation) {
        url = `${url}?project_id=${id}&organisation=${organisation}&num_sim_years=0&num_agents=-1`;
      }
      const response = await API.get("usimAPI", url);
      dispatch({
        type: START_SIMULATIONS_SUCCESS,
        payload: response,
      });

      dispatch(getProjectDetails(id, organisation));
    } catch (error) {
      dispatch({
        type: START_SIMULATIONS_FAILURE,
        payload: error,
      });
    }
  };
}

export const STOP_SIMULATIONS_REQUEST =
  "@@simulations/STOP_SIMULATIONS_REQUEST";
export const STOP_SIMULATIONS_SUCCESS =
  "@@simulations/STOP_SIMULATIONS_SUCCESS";
export const STOP_SIMULATIONS_FAILURE =
  "@@simulations/STOP_SIMULATIONS_FAILURE";

export function stopSimulations(id = "", organisation = "") {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: STOP_SIMULATIONS_REQUEST,
    });
    try {
      let url = "/run";
      if (organisation) {
        url = `${url}?project_id=${id}&organisation=${organisation}&num_sim_years=-1&num_agents=-1`;
      }
      const response = await API.del("usimAPI", url);
      dispatch({
        type: STOP_SIMULATIONS_SUCCESS,
        payload: response,
      });

      dispatch(getProjectDetails(id, organisation));
    } catch (error) {
      dispatch({
        type: STOP_SIMULATIONS_FAILURE,
        payload: error,
      });
    }
  };
}

export const ADD_SCENARIO_REQUEST = "@@simulations/ADD_SCENARIO_REQUEST";
export const ADD_SCENARIO_SUCCESS = "@@simulations/ADD_SCENARIO_SUCCESS";
export const ADD_SCENARIO_FAILURE = "@@simulations/ADD_SCENARIO_FAILURE";

export function addScenario(id = "", organisation = "", callback) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: ADD_SCENARIO_REQUEST,
    });
    try {
      let url = "/S3toSF";
      if (organisation) {
        url = `${url}?project_id=${id}&organisation=${organisation}`;
      }
      const response = await API.put("usimAPI", url);
      dispatch({
        type: ADD_SCENARIO_SUCCESS,
        payload: response,
      });

      dispatch(getProjectDetails(id, organisation));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ADD_SCENARIO_FAILURE,
        payload: error,
      });
    }
  };
}

export const REFRESH_SIMULATIONS_RUNTIME_REQUEST =
  "@@simulations/REFRESH_SIMULATIONS_RUNTIME_REQUEST";
export const REFRESH_SIMULATIONS_RUNTIME_SUCCESS =
  "@@simulations/REFRESH_SIMULATIONS_RUNTIME_SUCCESS";
export const REFRESH_SIMULATIONS_RUNTIME_FAILURE =
  "@@simulations/REFRESH_SIMULATIONS_RUNTIME_FAILURE";

export function refreshSimulationsRuntime(id = "", organisation = "") {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: REFRESH_SIMULATIONS_RUNTIME_REQUEST,
    });
    try {
      let url = "/refreshsimruntime";
      url = `${url}?project_id=${id}&organisation=${organisation}`;
      const response = await API.get("usimAPI", url);
      dispatch({
        type: REFRESH_SIMULATIONS_RUNTIME_SUCCESS,
        payload: response,
      });
      dispatch(getProjectDetails(id, organisation));
    } catch (error) {
      dispatch(getProjectDetails(id, organisation));
      dispatch({
        type: REFRESH_SIMULATIONS_RUNTIME_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_DOWNLOAD_RESULTS_REQUEST =
  "@@agentDesign/GET_DOWNLOAD_RESULTS_REQUEST";
export const GET_DOWNLOAD_RESULTS_SUCCESS =
  "@@agentDesign/GET_DOWNLOAD_RESULTS_SUCCESS";
export const GET_DOWNLOAD_RESULTS_FAILURE =
  "@@agentDesign/GET_DOWNLOAD_RESULTS_FAILURE";

export function getDownloadResults(
  id = "",
  organisation,
  tables = [],
  callback
) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_DOWNLOAD_RESULTS_REQUEST,
    });
    try {
      for (let i = 0; i < tables.length; i++) {
        setTimeout(async () => {
          const table_name = tables[i];
          let url = `/showdata?project_id=${id}&organisation=${organisation}&table_name=${table_name}`;

          const response = await API.get("usimAPI", url);
          const s3_presigned_url = response?.s3_presigned_url || "";
          if (s3_presigned_url) {
            const link = document.createElement("a");
            link.setAttribute("href", s3_presigned_url);
            document.body.appendChild(link);

            link.click();
          }
        }, 3000 * i);
      }

      dispatch({
        type: GET_DOWNLOAD_RESULTS_SUCCESS,
        payload: {},
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: GET_DOWNLOAD_RESULTS_FAILURE,
        payload: error,
      });
    }
  };
}
