import { Box } from "@mui/material";

export const styles = (theme) => {
  return {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
  };
};

const ProjectPageContents = ({ children }) => {
  return <Box sx={(theme) => styles(theme)}>{children}</Box>;
};

export default ProjectPageContents;
