import { API } from "aws-amplify";
import { toast } from "react-toastify";

export const GET_PROJECT_LIST_REQUEST = "@@projects/GET_PROJECT_LIST_REQUEST";
export const GET_PROJECT_LIST_SUCCESS = "@@projects/GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "@@projects/GET_PROJECT_LIST_FAILURE";

export function getProjectList(organisation = "") {
  return async (dispatch) => {
    dispatch({
      type: GET_PROJECT_LIST_REQUEST,
    });
    try {
      let url = "/projects";
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PROJECT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_PROJECT_DETAILS_REQUEST =
  "@@projects/GET_PROJECT_DETAILS_REQUEST";
export const GET_PROJECT_DETAILS_SUCCESS =
  "@@projects/GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAILURE =
  "@@projects/GET_PROJECT_DETAILS_FAILURE";

export function getProjectDetails(id = "", organisation = "") {
  return async (dispatch) => {
    dispatch({
      type: GET_PROJECT_DETAILS_REQUEST,
    });
    try {
      let url = `/projects/${id}`;
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PROJECT_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_PROJECT_DETAILS = "@@projects/CLEAR_PROJECT_DETAILS";

export function clearProjectDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PROJECT_DETAILS,
    });
  };
}

export const POST_PROJECT_REQUEST = "@@projects/POST_PROJECT_REQUEST";
export const POST_PROJECT_SUCCESS = "@@projects/POST_PROJECT_SUCCESS";
export const POST_PROJECT_FAILURE = "@@projects/POST_PROJECT_FAILURE";

export function createProject(value, organisation, callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_PROJECT_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      let url = "/projects";
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.post("usimAPI", url, init);
      dispatch({
        type: POST_PROJECT_SUCCESS,
        payload: response,
      });
      toast.success("Project created successfully!");
      dispatch(getProjectList(organisation));
      callback();
    } catch (error) {
      dispatch({
        type: POST_PROJECT_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_PROJECT_REQUEST = "@@projects/PUT_PROJECT_REQUEST";
export const PUT_PROJECT_SUCCESS = "@@projects/PUT_PROJECT_SUCCESS";
export const PUT_PROJECT_FAILURE = "@@projects/PUT_PROJECT_FAILURE";

export function updateProject(id, value, organisation, callback) {
  return async (dispatch) => {
    dispatch({
      type: PUT_PROJECT_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = `/projects/${id}`;
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.put("usimAPI", url, init);
      dispatch({
        type: PUT_PROJECT_SUCCESS,
        payload: response,
      });
      toast.success("Project updated successfully!");
      dispatch(getProjectList(organisation));
      callback();
    } catch (error) {
      dispatch({
        type: PUT_PROJECT_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLONE_PROJECT_REQUEST = "@@projects/CLONE_PROJECT_REQUEST";
export const CLONE_PROJECT_SUCCESS = "@@projects/CLONE_PROJECT_SUCCESS";
export const CLONE_PROJECT_FAILURE = "@@projects/CLONE_PROJECT_FAILURE";

export function cloneProject(id, organisation, clone_project, callback) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: CLONE_PROJECT_REQUEST,
    });
    try {
      const init = {
        body: {
          organisation: organisation,
          clone_project: clone_project,
          project_name: id,
        },
      };
      const response = await API.post("usimAPI", "/projects", init);
      dispatch({
        type: CLONE_PROJECT_SUCCESS,
        payload: response,
      });
      toast.success("Project cloned successfully!");
      dispatch(getProjectList(organisation));
      callback();
    } catch (error) {
      dispatch({
        type: CLONE_PROJECT_FAILURE,
        payload: error,
      });
    }
  };
}

export const SET_SHOW_PROJECT_SIDEBAR = "@@projects/SET_SHOW_PROJECT_SIDEBAR";

export function setShowProjectSidebar(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_SHOW_PROJECT_SIDEBAR,
      payload: value,
    });
  };
}

export const DELETE_PROJECT_REQUEST = "@@projects/DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "@@projects/DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "@@projects/DELETE_PROJECT_FAILURE";

export function deleteProject(id, organisation, callback) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PROJECT_REQUEST,
    });
    try {
      let url = `/projects/${id}`;
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.del("usimAPI", url);
      dispatch({
        type: DELETE_PROJECT_SUCCESS,
        payload: response,
      });
      toast.success("Project deleted successfully!");
      dispatch(getProjectList(organisation));
      callback();
    } catch (error) {
      dispatch({
        type: DELETE_PROJECT_FAILURE,
        payload: error,
      });
    }
  };
}

export function getProjectDetailsWithoutLoading(id = "", organisation = "") {
  return async (dispatch) => {
    try {
      let url = `/projects/${id}`;
      if (organisation) {
        url = `${url}?organisation=${organisation}`;
      }
      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_PROJECT_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_SIM_READY = "@@projects/CLEAR_SIM_READY";

export function clearSimReady() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SIM_READY,
    });
  };
}
