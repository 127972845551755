import { Box, Typography } from "@mui/material";
import { filterStyles } from "./styles";

const PageFilter = ({ children, title }) => {
  return (
    <Box sx={filterStyles}>
      <Box className="filter-title">
        <Typography variant="h4">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default PageFilter;
