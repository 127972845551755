import { Modal } from "components";
import SetupMFA from "./SetupMFA";
import { Box } from "@mui/material";

const MFASetupModal = ({ open, onClose }) => {
  return (
    <Modal
      top="20%"
      title="Setup Multi-Factor Authentication"
      open={open}
      showHeaderBorder={true}
      blurBackground={true}
    >
      <Box mt={1}>
        <SetupMFA onClose={onClose} />
      </Box>
    </Modal>
  );
};

export default MFASetupModal;
