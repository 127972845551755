const RETRY_KEY = "usim-frontend_retry-lazy-refreshed";

export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(RETRY_KEY) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(RETRY_KEY, "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(RETRY_KEY, "true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};
