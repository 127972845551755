import { Box } from "@mui/material";
import { getProductsChartData } from "actions/vehicle/vehicleProducts";
import { Loader, Modal } from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash";
import { options } from "./utils";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const VehicleChartModal = ({
  viewChartType,
  open,
  onClose,
  selectedProduct,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [selectedChartData, setSelectChartData] = useState([]);

  const project = useSelector((state) => state.projects?.details);
  const loading = useSelector((state) => state.vehicleProducts?.loading);
  const chartData = useSelector((state) => state.vehicleProducts?.chartData);

  useEffect(() => {
    if (selectedProduct && project?.item) {
      dispatch(
        getProductsChartData(
          project.item,
          project.organisation,
          selectedProduct
        )
      );
    }
  }, [selectedProduct, project]);

  useEffect(() => {
    if (viewChartType) {
      let title = "";
      switch (viewChartType) {
        case "modelInfrastructure": {
          title = "Model Infrastructure";
          break;
        }
        case "costs": {
          title = "Costs";
          break;
        }
        case "chargerLoadProfiles": {
          title = "EV Charging Profiles";
          break;
        }
        default: {
          title = "";
          break;
        }
      }
      setTitle(title);
      setSelectChartData(chartData[viewChartType] || []);
    }
  }, [chartData, viewChartType]);

  return (
    <Box>
      <Modal
        title={title}
        open={open}
        onClose={onClose}
        width={800}
        titleVariant="h3"
      >
        {loading && <Loader show={true} />}
        <Box>
          {!isEmpty(viewChartType) && (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                series: selectedChartData,
                tooltip: {
                  formatter: function () {
                    let x = "";
                    if (this.points.length > 0) {
                      x = this.points[0].x;
                    }
                    return ["<b>" + x + "</b><br/>"].concat(
                      this.points
                        ? this.points.map(function (point) {
                            return (
                              "<br/>" +
                              "<span style='color: " +
                              point.color +
                              "'>" +
                              "<b>" +
                              point.series.name +
                              " : " +
                              "</b>" +
                              "<b>" +
                              point?.y +
                              "<b>" +
                              "</span>" +
                              "<br/>" +
                              "<br/>"
                            );
                          })
                        : []
                    );
                  },
                  shared: true,
                  crosshairs: true,
                },
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default VehicleChartModal;
