import { API } from "aws-amplify";
import { toast } from "react-toastify";

export const USER_LIST_REQUEST = "@@user/USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "@@user/USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "@@user/USER_LIST_FAILURE";

export function getUserList() {
  return async (dispatch) => {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    try {
      const response = await API.get("usimAPI", "/user");
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const USER_DETAILS_REQUEST = "@@user/USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "@@user/USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "@@user/USER_DETAILS_FAILURE";

export function getUserDetails(id = "") {
  return async (dispatch) => {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("usimAPI", `/user/${id}`);
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_USER_DETAILS = "@@user/CLEAR_USER_DETAILS";

export function clearUserDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_USER_DETAILS,
    });
  };
}

export const POST_USER_REQUEST = "@@user/POST_USER_REQUEST";
export const POST_USER_SUCCESS = "@@user/POST_USER_SUCCESS";
export const POST_USER_FAILURE = "@@user/POST_USER_FAILURE";

export function createUser(value, callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_USER_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      const response = await API.post("usimAPI", `/user`, init);
      dispatch({
        type: POST_USER_SUCCESS,
        payload: response,
      });
      toast.success("User created successfully!");
      callback();
    } catch (error) {
      dispatch({
        type: POST_USER_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_USER_REQUEST = "@@user/PUT_USER_REQUEST";
export const PUT_USER_SUCCESS = "@@user/PUT_USER_SUCCESS";
export const PUT_USER_FAILURE = "@@user/PUT_USER_FAILURE";

export function updateUser(id, value, callback) {
  return async (dispatch) => {
    dispatch({
      type: PUT_USER_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      const response = await API.put("usimAPI", `/user/${id}`, init);
      dispatch({
        type: PUT_USER_SUCCESS,
        payload: response,
      });
      toast.success("User updated successfully!");
      callback();
    } catch (error) {
      dispatch({
        type: PUT_USER_FAILURE,
        payload: error,
      });
    }
  };
}
