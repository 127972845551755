import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from "actions/profile";

import { uSimError } from "utils/override";

const initialState = {
  loading: false,
  details: {
    preferred_mfa_setting: "",
  },
};

export default function profile(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: {
          preferred_mfa_setting: "",
          ...payload,
        },
      };
    }

    case GET_USER_PROFILE_FAILURE: {
      uSimError(payload?.message || "Something went wrong");
      return {
        ...state,
        loading: false,
        error: {
          ...payload,
          message: payload?.message || "Something went wrong",
        },
      };
    }

    default:
      return state;
  }
}
