import { API } from "aws-amplify";
import { getProjectDetails } from "./projects";
import { toast } from "react-toastify";

export const CHECK_VALIDATION_STATUS_REQUEST =
  "@@validation/CHECK_VALIDATION_STATUS_REQUEST";
export const CHECK_VALIDATION_STATUS_SUCCESS =
  "@@validation/CHECK_VALIDATION_STATUS_SUCCESS";
export const CHECK_VALIDATION_STATUS_FAILURE =
  "@@validation/CHECK_VALIDATION_STATUS_FAILURE";

export function checkValidationStatus(id = "", organisation, step) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: CHECK_VALIDATION_STATUS_REQUEST,
    });
    try {
      const response = await API.get(
        "usimAPI",
        `/checkvalidationstatus?project_id=${id}&organisation=${organisation}&step=${step.step}`
      );
      dispatch({
        type: CHECK_VALIDATION_STATUS_SUCCESS,
        payload: {
          [step.key]: response.message,
          projectId: id,
        },
      });
      toast.success(response.message);
    } catch (error) {
      dispatch({
        type: CHECK_VALIDATION_STATUS_FAILURE,
        payload: {
          [step.key]: error?.response?.data.message,
          projectId: id,
        },
      });
    }
  };
}

export const CLEAR_VALIDATION_STATUS = "@@validation/CLEAR_VALIDATION_STATUS";

export function clearValidationStatus() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_VALIDATION_STATUS,
    });
  };
}

export const FINAL_STEP_VALIDATION_REQUEST =
  "@@validation/FINAL_STEP_VALIDATION_REQUEST";
export const FINAL_STEP_VALIDATION_SUCCESS =
  "@@validation/FINAL_STEP_VALIDATION_SUCCESS";
export const FINAL_STEP_VALIDATION_FAILURE =
  "@@validation/FINAL_STEP_VALIDATION_FAILURE";

export function finalStepValidation(id = "", organisation, step) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: FINAL_STEP_VALIDATION_REQUEST,
    });
    try {
      const response = await API.get(
        "usimAPI",
        `/${step.apiUrl}?project_id=${id}&organisation=${organisation}&step=${step.step}`
      );
      dispatch({
        type: FINAL_STEP_VALIDATION_SUCCESS,
      });
      toast.success(response.message);
      dispatch(getProjectDetails(id));
      dispatch(checkValidationStatus(id, organisation, step));
    } catch (error) {
      dispatch({
        type: FINAL_STEP_VALIDATION_FAILURE,
        payload: error?.response?.data,
      });
      dispatch(checkValidationStatus(id, organisation, step));
    }
  };
}

export const GET_LAST_INPUT_ERROR_REQUEST =
  "@@validation/GET_LAST_INPUT_ERROR_REQUEST";
export const GET_LAST_INPUT_ERROR_SUCCESS =
  "@@validation/GET_LAST_INPUT_ERROR_SUCCESS";
export const GET_LAST_INPUT_ERROR_FAILURE =
  "@@validation/GET_LAST_INPUT_ERROR_FAILURE";

export function getLastInputError(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_LAST_INPUT_ERROR_REQUEST,
    });
    try {
      const response = await API.get(
        "usimAPI",
        `/lastinputerror?project_id=${id}&organisation=${organisation}`
      );
      dispatch({
        type: GET_LAST_INPUT_ERROR_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_LAST_INPUT_ERROR_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}

export const PUT_LAST_INPUT_ERROR_REQUEST =
  "@@validation/PUT_LAST_INPUT_ERROR_REQUEST";
export const PUT_LAST_INPUT_ERROR_SUCCESS =
  "@@validation/PUT_LAST_INPUT_ERROR_SUCCESS";
export const PUT_LAST_INPUT_ERROR_FAILURE =
  "@@validation/PUT_LAST_INPUT_ERROR_FAILURE";

export function resetLastInputError(id = "", organisation) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: PUT_LAST_INPUT_ERROR_REQUEST,
    });
    try {
      const response = await API.put(
        "usimAPI",
        `/lastinputerror?project_id=${id}&organisation=${organisation}`
      );
      dispatch({
        type: PUT_LAST_INPUT_ERROR_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PUT_LAST_INPUT_ERROR_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
