import { API } from "aws-amplify";
import { toast } from "react-toastify";

export const ORGANISATION_LIST_REQUEST =
  "@@organisation/ORGANISATION_LIST_REQUEST";
export const ORGANISATION_LIST_SUCCESS =
  "@@organisation/ORGANISATION_LIST_SUCCESS";
export const ORGANISATION_LIST_FAILURE =
  "@@organisation/ORGANISATION_LIST_FAILURE";

export function getOrganisationList() {
  return async (dispatch) => {
    dispatch({
      type: ORGANISATION_LIST_REQUEST,
    });
    try {
      const response = await API.get("usimAPI", "/organisation");
      dispatch({
        type: ORGANISATION_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ORGANISATION_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const ORGANISATION_DETAILS_REQUEST =
  "@@organisation/ORGANISATION_DETAILS_REQUEST";
export const ORGANISATION_DETAILS_SUCCESS =
  "@@organisation/ORGANISATION_DETAILS_SUCCESS";
export const ORGANISATION_DETAILS_FAILURE =
  "@@organisation/ORGANISATION_DETAILS_FAILURE";

export function getOrganisationDetails(id = "") {
  return async (dispatch) => {
    dispatch({
      type: ORGANISATION_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("usimAPI", `/organisation/${id}`);
      dispatch({
        type: ORGANISATION_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ORGANISATION_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_ORGANISATION_DETAILS =
  "@@organisation/CLEAR_ORGANISATION_DETAILS";

export function clearOrganisationDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ORGANISATION_DETAILS,
    });
  };
}

export const POST_ORGANISATION_REQUEST =
  "@@organisation/POST_ORGANISATION_REQUEST";
export const POST_ORGANISATION_SUCCESS =
  "@@organisation/POST_ORGANISATION_SUCCESS";
export const POST_ORGANISATION_FAILURE =
  "@@organisation/POST_ORGANISATION_FAILURE";

export function createOrganisation(value, callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_ORGANISATION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      const response = await API.post("usimAPI", `/organisation`, init);
      dispatch({
        type: POST_ORGANISATION_SUCCESS,
        payload: response,
      });
      toast.success("Organisation created successfully!");
      dispatch(getOrganisationList());
      callback();
    } catch (error) {
      dispatch({
        type: POST_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_ORGANISATION_REQUEST =
  "@@organisation/PUT_ORGANISATION_REQUEST";
export const PUT_ORGANISATION_SUCCESS =
  "@@organisation/PUT_ORGANISATION_SUCCESS";
export const PUT_ORGANISATION_FAILURE =
  "@@organisation/PUT_ORGANISATION_FAILURE";

export function updateOrganisation(id, value, callback) {
  return async (dispatch) => {
    dispatch({
      type: PUT_ORGANISATION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      const response = await API.put("usimAPI", `/organisation/${id}`, init);
      dispatch({
        type: PUT_ORGANISATION_SUCCESS,
        payload: response,
      });
      toast.success("Organisation updated successfully!");
      dispatch(getOrganisationList());
      callback();
    } catch (error) {
      dispatch({
        type: PUT_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}
