import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  KeyboardArrowRight,
  CheckCircleOutline,
  Loop,
  LockOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "components";
import { isEmpty } from "lodash";

const styles = () => {
  return {
    "& .title": {
      display: "flex",
      alignItems: "center",
      "& .ready-to-start": {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#DDEBFF",
        color: "#2B7FE0",
        padding: "4px 12px",
        borderRadius: "7px",
        marginRight: "8px",
      },
    },
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontWeight: 500,
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#F6F7FC",
      color: "#2B7FE0",
      borderRadius: "4px",
      "& .MuiListItemIcon-root": {
        color: "#2B7FE0",
      },
    },
    "& .MuiListItemIcon-root": {
      mr: 0,
      color: "#A5ABB5",
    },
    "& .loaderbox": {
      width: "100%",
      height: "300px",
    },
  };
};

const PageRouteTree = ({
  title,
  description,
  menu,
  isStarted = false,
  loading = false,
  stepValidation = "",
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const project = useSelector((state) => state.projects?.details);

  const onClickMenu = (subMenu) => {
    if (unavailable(subMenu)) return;
    navigate(`/project/upload-data/${id}/${menu.route}/${subMenu.route}`);
  };

  const renderCompleted = (subMenu) => {
    if (
      !project[subMenu.inputKey] ||
      (project[subMenu.inputKey] &&
        project[subMenu.inputKey] === "Error in Last Upload")
    ) {
      if (project[subMenu.stepValidationKey] === "True") {
        return (
          <ListItemIcon>
            <CheckCircleOutline
              sx={{
                marginRight: "4px",
                color: "#229A16 !important",
              }}
            />
          </ListItemIcon>
        );
      } else {
        return;
      }
    }

    return (
      <ListItemIcon>
        <CheckCircleOutline
          sx={{
            marginRight: "4px",
            color: "#2B7FE0 !important",
          }}
        />
      </ListItemIcon>
    );
  };

  const isValidating =
    !isEmpty(stepValidation) &&
    project &&
    project[stepValidation] &&
    !["True", "False"].includes(project[stepValidation]);

  const unavailable = (subMenu) => {
    if (
      subMenu?.validateRequired &&
      project[subMenu?.validateRequired] !== "True"
    ) {
      return true;
    } else if (subMenu?.inputRequired && subMenu?.inputRequired.length > 0) {
      let count = 0;
      subMenu.inputRequired.forEach((it) => {
        count = project[it] ? count + 1 : count;
      });

      return subMenu?.inputRequired.length !== count;
    }

    return false;
  };

  return (
    <Box mx={10} sx={styles}>
      {loading ? (
        <Box className="loaderbox">
          <Loader show={true} />
        </Box>
      ) : (
        <Box>
          <Box className="title" mb={1}>
            <Typography variant="h3" mr={2}>
              {title}
            </Typography>
            {!isStarted && (
              <Typography
                className="ready-to-start"
                variant="body2"
                fontWeight={500}
              >
                Ready to Start
              </Typography>
            )}

            {isValidating && (
              <Box className="ready-to-start">
                <Loop className="rotateIcon" />
                <Typography variant="body2" fontWeight={500}>
                  {project[stepValidation]}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography variant="body2" mb={2}>
            {description}
          </Typography>

          <List>
            {menu?.children &&
              menu?.children.map((subMenu, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    "&:first-of-type": {
                      borderTop: "1px solid #D6DDEA",
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() => onClickMenu(subMenu)}
                    sx={{
                      minHeight: 60,
                      px: 0,
                      borderBottom: "1px solid #D6DDEA",
                      pointerEvents: unavailable(subMenu) ? "none" : undefined,
                    }}
                  >
                    {renderCompleted(subMenu)}
                    <ListItemText primary={subMenu.label} />

                    <ListItemIcon>
                      {unavailable(subMenu) ? (
                        <LockOutlined className="arrowicon" />
                      ) : (
                        <KeyboardArrowRight className="arrowicon" />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default PageRouteTree;
