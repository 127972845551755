import {
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_CHART_DATA_REQUEST,
  GET_PRODUCTS_CHART_DATA_SUCCESS,
  GET_PRODUCTS_CHART_DATA_FAILURE,
} from "actions/appliance/applianceProducts";

const initialChartData = {
  profile: [],
};

const initialState = {
  loading: false,
  uploading: false,
  list: [],
  chartData: initialChartData,
};

const getChartItem = (payload, key) => {
  const item = payload[key];
  let objKey = "";
  if (item) {
    Object.keys(item).forEach((it, i) => {
      if (i === 0) {
        objKey = it;
      }
    });

    return item[objKey];
  } else {
    return undefined;
  }
};

const getLoadProfiles = (payload) => {
  const item = getChartItem(payload, "load_profiles");
  if (item) {
    const data = [];
    item["kwh_values"].forEach((it, i) => {
      const citem = [];
      citem.push(item.hours[i]);
      citem.push(it);
      data.push(citem);
    });
    return [
      {
        data: data,
        name: item.name,
      },
    ];
  } else {
    return [];
  }
};

export default function applianceProducts(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
            profile: it.ID,
          };
        }),
      };
    }

    case GET_PRODUCTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case GET_PRODUCTS_CHART_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        chartData: initialChartData,
      };
    }

    case GET_PRODUCTS_CHART_DATA_SUCCESS: {
      const profile = getLoadProfiles(payload) || [];

      return {
        ...state,
        loading: false,
        chartData: {
          ...payload,
          profile,
        },
      };
    }

    case GET_PRODUCTS_CHART_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        chartData: initialChartData,
      };
    }

    default:
      return state;
  }
}
