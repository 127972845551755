import { isNil } from "lodash";

export const getProjectCompletion = (project) => {
  return Math.round((project?.data_completion_count / 38) * 100) || 0;
};

export const isInputInTable = (menu, project) => {
  if (!project) return false;
  let value = true;
  menu?.children
    ?.filter((it) => !it.noInput)
    .forEach((it) => {
      if (!it.inputKey) return;
      value = value && !isNil(project[it.inputKey]);
    });
  return value;
};

export const isInputStarted = (menu, project) => {
  if (!project) return false;
  let value = false;
  menu?.children?.forEach((it) => {
    value = value || project[it.inputKey];
  });
  return value;
};
